import React, {createContext, useContext, useDebugValue, useEffect, useState} from "react";
import {getStorageValue} from "../tools/cookie-handler";
import i18n from "../i18n";
import {enUS as dateFnsEnUS, de as dateFnsDe, pl as dateFnsPl, sv as dateFnsSv, es as dateFnsEs, fr as dateFnsFr, it as dateFnsIt, ja as dateFnsJa, ptBR as dateFnsPtBR, zhCN as dateFnsZhCN} from "date-fns/locale";
import {enUS as muiEnUS, frFR as muiFrFR, deDE as muiDeDE, plPL as muiPlPL, svSE as muiSvSE, esES as muiEsES, itIT as muiItIT, jaJP as muiJaJP, ptBR as muiPtBR, zhCN as muiZhCN} from "@mui/x-data-grid";
import {CUSTOMER_ADDRESS, CUSTOMER_INFOS, getData} from "../services/customer.service";
import useAuth from "../hooks/useAuth";
import {logInConsole, logInConsoleObject} from "../tools/debug";
import {COUNTRIES_LIST, getCountries} from "../services/countries.service";
import AuthService from "../services/auth.service";

const UserContext = createContext({});

export const UserProvider = ({ children }) => {
    const [ user, setUser ] = useState({});

    const {auth} = useAuth();
    const [connectToBackend, setConnectToBackend] = useState(false);
    const [errorBackendConnection, setErrorBackendConnection] = useState(false);

    // CURRENCY
    const [activeCurrency, setActiveCurrency] = useState(getStorageValue('userPreferences')?.activeCurrency || "$");
    const [currencyCode, setCurrencyCode] = useState(getStorageValue('userPreferences')?.currencyCode || "USD");
    const [orderCurrency, setOrderCurrency] = useState(getStorageValue('userPreferences')?.activeCurrency || "$");
    const [currenciesAllowed, setCurrenciesAllowed] = useState([]);
    // LANGUAGE
    const [activeLanguage, setActiveLanguage] = useState(getStorageValue('userPreferences')?.activeLanguage || '');
    const [muiLocale, setMuiLocale] = useState(dateFnsEnUS);
    const [dataGridLocale, setDataGridLocale] = useState(muiEnUS.components.MuiDataGrid.defaultProps.localeText);
    const [localesList, setLocalesList] = useState([]);
    const [companyLocale, setCompanyLocale] = useState("");

    // Change MUI Calendar lang
    useEffect(() => {
        if (activeLanguage === "CN"){
            setMuiLocale(dateFnsZhCN)
            setDataGridLocale(muiZhCN.components.MuiDataGrid.defaultProps.localeText)
        }
        if (activeLanguage === "DE"){
            setMuiLocale(dateFnsDe)
            setDataGridLocale(muiDeDE.components.MuiDataGrid.defaultProps.localeText)
        }
        if (activeLanguage === "EN"){
            setMuiLocale(dateFnsEnUS)
            setDataGridLocale(muiEnUS.components.MuiDataGrid.defaultProps.localeText)
        }
        if (activeLanguage === "ES"){
            setMuiLocale(dateFnsEs)
            setDataGridLocale(muiEsES.components.MuiDataGrid.defaultProps.localeText)
        }
        if (activeLanguage === "FR"){
            setMuiLocale(dateFnsFr)
            setDataGridLocale(muiFrFR.components.MuiDataGrid.defaultProps.localeText)
        }
        if (activeLanguage === "IT"){
            setMuiLocale(dateFnsIt)
            setDataGridLocale(muiItIT.components.MuiDataGrid.defaultProps.localeText)
        }
        if (activeLanguage === "JA"){
            setMuiLocale(dateFnsJa)
            setDataGridLocale(muiJaJP.components.MuiDataGrid.defaultProps.localeText)
        }
        if (activeLanguage === "PT"){
            setMuiLocale(dateFnsPtBR)
            setDataGridLocale(muiPtBR.components.MuiDataGrid.defaultProps.localeText)
        }
    }, [activeLanguage])

    // 1st visit, no cookies, setting up user lang. On refresh, load the good lang
    useEffect(() => {
        // if no cookies with use the browser locale to update the top bar menu
        if (!getStorageValue('userPreferences')){
            setActiveLanguage("EN");
            return
        }
        activeLanguage === "CN" && i18n.changeLanguage('cnCN');
        activeLanguage === "DE" && i18n.changeLanguage('deDE');
        activeLanguage === "EN" && i18n.changeLanguage('enEN');
        activeLanguage === "ES" && i18n.changeLanguage('esES');
        activeLanguage === "FR" && i18n.changeLanguage('frFR');
        activeLanguage === "IT" && i18n.changeLanguage('itIT');
        activeLanguage === "JA" && i18n.changeLanguage('jaJP');
        activeLanguage === "PT" && i18n.changeLanguage('ptBR');
    }, [i18n]);

    // Handle cookie saving with user preferences
    useEffect(() => {
        localStorage.setItem('userPreferences', JSON.stringify({
            activeCurrency,
            activeLanguage,
            currencyCode
        }));
    }, [activeCurrency,activeLanguage]);

    //LOGIN
    const [isFirstConnection, setIsFirstConnection] = useState()

    //USER ACCOUNT
    const [customerInfo, setCustomerInfo] = useState([]);

    //ACCOUNT SIDEBAR
    const [businessUnit, setBusinessUnit] = useState('');
    const [company, setCompany] = useState([]);
    const [companyId, setCompanyId] = useState([]);
    const [isVerified, setIsVerified] = useState(false);
    const [emailOfReferrer, setEmailOfReferrer] = useState('');
    const [firstNameOfReferrer, setFirstNameOfReferrer] = useState('');
    const [lastNameOfReferrer, setLastNameOfReferrer] = useState('');

    //ACCOUNT SETTINGS
    const [firstName, setFirstName] = useState(customerInfo?.firstName || '');
    const [lastName, setLastName] = useState(customerInfo?.lastName || '');
    const [phoneNumber, setPhoneNumber] = useState(customerInfo?.phoneNumber || '');
    const [email, setEmail] = useState(customerInfo?.email || '');
    const [newPassword, setNewPassword] = useState(customerInfo?.password || '');
    const [isTestAccount, setIsTestAccount] = useState(false);
    // TODO IS THIS REALLY NECESSARY ? parce qu'on pas plus par les locales de bu donc pourquoi tout mettre en place
    const [businessUnitLocale, setBusinessUnitLocale] = useState('');

    //USER MAIL PREFERENCES
    const [customerMailPref, setCustomerMailPref] = useState({});

    const [technicalDocsEmail, setTechnicalDocsEmail] = useState(customerMailPref?.technicalDocsEmail || '');
    const [extraTechnicalDocsEmail, setExtraTechnicalDocsEmail] = useState(customerMailPref?.extraTechnicalDocsEmail || '');

    const [orderConfirmationEmail, setOrderConfirmationEmail] = useState(customerMailPref?.orderConfirmationEmail || '');
    const [extraOrderConfirmationEmail, setExtraOrderConfirmationEmail] = useState(customerMailPref?.extraOrderConfirmationEmail || '');

    const [deliveryAndInvoiceEmail, setDeliveryAndInvoiceEmail] = useState(customerMailPref?.deliveryAndInvoiceEmail || '');
    const [extraDeliveryAndInvoiceEmail, setExtraDeliveryAndInvoiceEmail] = useState(customerMailPref?.extraDeliveryAndInvoiceEmail || '');

    //USER ADDRESSES
    const [countriesList, setCountriesList] = useState([]);
    const [availableCountries, setAvailableCountries] = useState([])
    const [customerAddress, setCustomerAddress] = useState([]);
    const [companyAddresses, setCompanyAddresses] = useState([]);
    const [addressId, setAddressId] = useState();

    const getAddress = (id) => {
        return customerAddress.find(address => address.id === id);
    }
    const getCompanyAddress = (id) => {
        return companyAddresses.find(address => address.id === id);
    }

    const [companyAddr, setCompanyAddr] = useState(getAddress(addressId)?.company || '');
    const [aliasAddr, setAliasAddr] = useState(getAddress(addressId)?.company || '');
    const [firstNameAddr, setFirstNameAddr] = useState(getAddress(addressId)?.firstName || '');
    const [lastNameAddr, setLastNameAddr] = useState(getAddress(addressId)?.lastName || '');
    const [phoneNumberAddr, setPhoneNumberAddr] = useState(getAddress(addressId)?.phoneNumber || '');
    const [street, setStreet] = useState(getAddress(addressId)?.street || '');
    const [postcode, setPostcode] = useState(getAddress(addressId)?.postcode || '');
    const [city, setCity] = useState(getAddress(addressId)?.city || '');
    const [countryCode, setCountryCode] = useState(getAddress(addressId)?.countryCode || '');
    const [isDefaultAddress, setIsDefaultAddress] = useState(getAddress(addressId)?.isDefaultAddress || false);
    const [isPrivateAddress, setIsPrivateAddress] = useState(getAddress(addressId)?.isPrivateAddress || false);

    const [isDataFullyLoaded, setIsDataFullyLoaded] = useState(false)

    //SYNC DATA FROM DB
    useEffect(() => {
        if (auth.customerId !== undefined) {
            logInConsole(`Front syncing with DataBase : Account settings & Addresses`, 'chocolate');
            syncInfoFromDB();
            syncMailPrefFromDB();
            syncAddrFromDB();
        }
    }, [auth.customerId])

    const syncInfoFromDB = () => {

        //GET CUSTOMER INFOS
        (async () => {
            setConnectToBackend(true)
            await getData(CUSTOMER_INFOS + '/customized', auth.customerId)
                .then(
                    (response) => {
                        setCustomerInfo(response);
                        setBusinessUnit(response.businessUnit);
                        setCompany(response.company.name);
                        setCompanyId(response.company.id);
                        setIsVerified(response.isVerified);
                        setFirstName(response.firstName);
                        setLastName(response.lastName);
                        setPhoneNumber(response.phoneNumber);
                        setEmail(response.email);
                        setEmailOfReferrer(response.company.emailOfReferrer);
                        setFirstNameOfReferrer(response.company.firstnameOfReferrer);
                        setLastNameOfReferrer(response.company.lastnameOfReferrer);
                        setBusinessUnitLocale(response.businessUnitLocale);

                        response.isTestAccount !== undefined &&  setIsTestAccount(response.isTestAccount);

                        const baseCurrency = response.currenciesAvailable.baseCurrency;
                        const counterCurrencies = response.currenciesAvailable.counterCurrency;

                        const allCurrencies = [baseCurrency];

                        if (counterCurrencies.length > 0) {
                            for (let i = 0; i < counterCurrencies.length; i++) {
                                if (response.businessUnit === 'default_channel' && !response.isTestAccount) {
                                    counterCurrencies[i].code === "EUR" && allCurrencies.push(counterCurrencies[i]);
                                }
                                else {
                                    allCurrencies.push(counterCurrencies[i]);
                                }

                            }
                        }

                        logInConsole("Currencies available :", "blue");
                        logInConsoleObject(allCurrencies);

                        setCurrenciesAllowed(allCurrencies);

                        logInConsole(`Synchronized with success : Customer infos`, 'green');

                        //GET COMPANY ADDRESSES
                        syncCompanyAddressesFromDB(response.company.id);


                    },
                    (error) => {
                        logInConsole(`Error when obtaining customer infos`, 'red');
                        //Test pour résoudre le problème de connexion refresh token
                        AuthService.logout();
                        setErrorBackendConnection(true);
                    }
                ).finally(
                () => {
                    setConnectToBackend(false);
                }
            )
        })();

    }
    const syncMailPrefFromDB = () => {
        //GET CUSTOMER MAIL PREFERENCES
        (async () => {
            setConnectToBackend(true)
            await getData(CUSTOMER_INFOS + '/mail-preferences', auth.customerId)
                .then(
                    (response) => {
                        setCustomerMailPref(response);

                        response.technicalDocsEmail !== null && setTechnicalDocsEmail(response.technicalDocsEmail);
                        response.extraTechnicalDocsEmail !== null && setExtraTechnicalDocsEmail(response.extraTechnicalDocsEmail)

                        response.orderConfirmationEmail !== null && setOrderConfirmationEmail(response.orderConfirmationEmail)
                        response.extraOrderConfirmationEmail !== null && setExtraOrderConfirmationEmail(response.extraOrderConfirmationEmail)

                        response.deliveryAndInvoiceEmail !== null && setDeliveryAndInvoiceEmail(response.deliveryAndInvoiceEmail)
                        response.extraDeliveryAndInvoiceEmail !== null && setExtraDeliveryAndInvoiceEmail(response.extraDeliveryAndInvoiceEmail)

                        setLocalesList(response.locales);
                        setCompanyLocale(response.companyLocale);

                        logInConsole(`Synchronized with success : Customer mail preferences`, 'green');
                    },
                    (error) => {
                        logInConsole(`Error when obtaining customer infos`, 'red');
                        setErrorBackendConnection(true);
                    }
                ).finally(
                () => {
                    setConnectToBackend(false);
                }
            )
        })();
    }
    const syncAddrFromDB = () => {
        //GET CUSTOMER ADDRESSES
        (async () => {
            setConnectToBackend(true)
            await getData(CUSTOMER_ADDRESS + '/ID/customized'.replace('ID', auth.customerId))
                .then(
                    (response) => {
                        setCustomerAddress(response);
                        logInConsole(`Synchronized with success : Addresses`, 'green');
                        // console.log('Addresses : ',response);
                    },
                    (error) => {
                        logInConsole(`Error when obtaining customer addresses`, 'red');
                        setErrorBackendConnection(true);
                    }
                ).finally(
                    () => {
                        setConnectToBackend(false);
                    }
            )
        })();

        // GET COUNTRIES LIST
        (async () => {
            setConnectToBackend(true);
            await getCountries(COUNTRIES_LIST, auth.customerId)
                .then(
                    (response) => {
                        setCountriesList(response);
                        logInConsole(`Countries list for address set`, 'forestgreen');
                    },
                    (error) => {
                        console.log('error when obtaining countries list');
                    }
                ).finally(
                    () => {
                        setConnectToBackend(false);
                    }
            )
        })();
    }
    const syncCompanyAddressesFromDB = (companyId) => {
        //GET COMPANY ADDRESSES
        (async () => {
            await getData(CUSTOMER_ADDRESS + '/' + companyId + '/' + auth.customerId + '/customized').then(
                (response) => {
                    setCompanyAddresses(response);
                    logInConsole(`Synchronized with success : Company addresses`, 'green');
                    setIsDataFullyLoaded(true);
                },
                (error) => {
                    logInConsole(`Error when obtaining company addresses`, 'red');
                    setErrorBackendConnection(true);
                }
            )
        })();
    }


    /**
     * Setting a countries list depending on customer addresses
     * Avoiding order in a country where he got no address to display in cart
     */
    useEffect(() => {
        // if(companyAddresses.length === 0) {
        //     return
        // }
        const customerAndCompanyAddresses = customerAddress.concat(companyAddresses);
        let _availableCountries = []
        for (let _address in customerAndCompanyAddresses) {
            for (let _country in countriesList) {
                if (countriesList[_country].code === customerAndCompanyAddresses[_address].countryCode) {
                    if (!_availableCountries.includes(countriesList[_country])) {
                        _availableCountries.push(countriesList[_country])
                    }
                }
            }
        }

        console.log("available countries:", _availableCountries);
        setAvailableCountries(_availableCountries);

    }, [isDataFullyLoaded]);
    /**
     * Search for the country defined in the customer default address
     * @returns {{}}
     */
    const defaultCountryFromAddresses = () => {
        let defaultAddress = {}
        let defaultCountry = {}

        for (let i in customerAddress) {
            if (customerAddress[i].isDefaultAddress) {
                defaultAddress = customerAddress[i];
                break;
            }
        }

        for (let i in availableCountries) {
            if (defaultAddress.countryCode === availableCountries[i].code) {
                defaultCountry = availableCountries[i];
                break;
            }
        }

        console.log(defaultCountry);
        return defaultCountry;
    }

    return (
        <UserContext.Provider value={{
            user, setUser,
            auth,
            isFirstConnection, setIsFirstConnection,
            connectToBackend, setConnectToBackend,
            errorBackendConnection, setErrorBackendConnection,
            activeCurrency, setActiveCurrency,
            currencyCode, setCurrencyCode,
            orderCurrency, setOrderCurrency,
            activeLanguage, setActiveLanguage,
            muiLocale, setMuiLocale,
            dataGridLocale, setDataGridLocale,
            customerInfo, setCustomerInfo,
            currenciesAllowed, setCurrenciesAllowed,
            businessUnit, setBusinessUnit,
            company, setCompany,
            companyId, setCompanyId,
            isVerified, setIsVerified,
            emailOfReferrer, setEmailOfReferrer,
            firstNameOfReferrer, setFirstNameOfReferrer,
            lastNameOfReferrer, setLastNameOfReferrer,
            firstName, setFirstName,
            lastName, setLastName,
            phoneNumber, setPhoneNumber,
            email, setEmail,
            newPassword, setNewPassword,
            isTestAccount, setIsTestAccount,
            businessUnitLocale,
            localesList,
            companyLocale, setCompanyLocale,
            customerMailPref, setCustomerMailPref,
            technicalDocsEmail, setTechnicalDocsEmail,
            extraTechnicalDocsEmail, setExtraTechnicalDocsEmail,
            orderConfirmationEmail, setOrderConfirmationEmail,
            extraOrderConfirmationEmail, setExtraOrderConfirmationEmail,
            deliveryAndInvoiceEmail, setDeliveryAndInvoiceEmail,
            extraDeliveryAndInvoiceEmail, setExtraDeliveryAndInvoiceEmail,
            countriesList, setCountriesList,
            availableCountries,
            customerAddress, setCustomerAddress,
            companyAddresses, setCompanyAddresses,
            addressId, setAddressId,
            companyAddr, setCompanyAddr,
            aliasAddr, setAliasAddr,
            firstNameAddr, setFirstNameAddr,
            lastNameAddr, setLastNameAddr,
            phoneNumberAddr, setPhoneNumberAddr,
            street, setStreet,
            postcode, setPostcode,
            city, setCity,
            countryCode, setCountryCode,
            isDefaultAddress, setIsDefaultAddress,
            isPrivateAddress, setIsPrivateAddress,
            getAddress,getCompanyAddress,
            syncInfoFromDB,
            syncMailPrefFromDB,
            syncAddrFromDB,
            syncCompanyAddressesFromDB,
            defaultCountryFromAddresses
        }} displayName="User">
            {children}
        </UserContext.Provider>
    )
}

export default UserContext;
