import React, {useContext, useEffect, useRef, useState} from "react";
import {Box, Checkbox, CircularProgress, FormControlLabel, FormGroup, Snackbar} from "@mui/material";
import {useNavigate} from "react-router-dom";
import Typography from "@mui/material/Typography";
import './payment.scss'
import {ButtonIcapeGreen, ButtonIcapeOutlined} from "../../button/button-icape";
import AccountBalanceTwoToneIcon from '@mui/icons-material/AccountBalanceTwoTone';
import CreditCardTwoToneIcon from '@mui/icons-material/CreditCardTwoTone';
import {PayPalScriptProvider} from '@paypal/react-paypal-js';
import {useTranslation} from "react-i18next";
import UserContext from "../../../context/UserProvider";
import {getStorageValue} from "../../../tools/cookie-handler";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import {Alert} from "@mui/lab";
import {logInConsole} from "../../../tools/debug";


function Payment(props) {

    const {t} = useTranslation();
    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();
    const orderToken = getStorageValue("cart")?.orderToken;
    const [isAgreeWithTermsOfService, setIsAgreeWithTermsOfService] = useState(false);
    const {activeCurrency, businessUnit, isTestAccount, setOrderCurrency} = useContext(UserContext);
    const [paymentMethod, setPaymentMethod] = useState('');
    const invoiceTotalPaypal = props.invoiceTotalPaypal;
    const repeatOrderMode = props.repeatOrderMode;
    const {isVerified} = useContext(UserContext);

    const handleIsAgreeWithTermsOfService = (e) => {
        setIsAgreeWithTermsOfService(e.target.checked)
    }
    const handlePaymentChoose = (_paymentMethod) => {
        setPaymentMethod(_paymentMethod);
        localStorage.setItem('cart', JSON.stringify({
            ...getStorageValue('cart'),
            checkout: { ...getStorageValue('cart').checkout, method: _paymentMethod }
        }));
        props.handlePaymentChoseByCustomer(getPaymentMethodeCodeByName(_paymentMethod));
    }
    const getPaymentMethodeCodeByName = (methodName) => {
        let code;
        if(props.paymentMethods.length>0)
        {
            code = props.paymentMethods.find(paymentMethod => paymentMethod.name.toLowerCase() === methodName.toLowerCase()).code;
        }else {
            code = methodName;
        }
        return code;
    }

    const [isPaymentMethodEnabled, setIsPaymentMethodEnabled] = useState({
        bank: false,
        card: false,
        paypal: false,
    })
    const [isPaypalLoading, setIsPaypalLoading] = useState(false);
    const [isPaypalError, setIsPaypalError] = useState(false)
    const [buPaypalData, setBuPaypalData] = useState({"clientId":null, "token":null})

    const paypalRef = useRef();

    useEffect(() => {
        if(buPaypalData.clientId && window.paypal)
        {
            window.paypal.Buttons({
                createOrder: (data, actions) => {
                    return actions.order.create({
                        purchase_units: [{
                            amount: {
                                //TODO Correct curency
                                currency_code: 'USD',
                                value: invoiceTotalPaypal,
                            },
                        }],
                    });
                },
                onApprove: (data, actions) => {
                    return actions.order.capture().then(details => {
                        // Call your server to mark the order as paid
                        handlePaymentChoose("paypal");

                        axiosPrivate.post('/shop/payment/pay-order-by-paypal', { orderToken, paypalDetails: details })
                            .then(async response => {
                                await props.uploadCustomerDocuments();
                                navigate('/checkout', {replace: true});
                            })
                            .catch(error => {
                                console.error('Error marking order as paid:', error);
                            });
                    });
                },
            }).render(paypalRef.current);
        }
    }, [buPaypalData, window.paypal]);

    useEffect(() => {
        if(!props.isCartReadyToProcessPayment || (props.isCartReadyToProcessPayment && !isPaymentMethodEnabled.paypal)){
            return
        }
        // Call your server to get the PayPal client token
        logInConsole("Paypal button preparation","aquamarine");
        setIsPaypalLoading(true);
        axiosPrivate.post('/shop/payment/get-paypal-token', { orderToken })
            .then(response => {
                const { clientId, token } = response.data;
                setBuPaypalData({ clientId, token })
                // Render the PayPal button with the obtained client token
                paypalRef.current.innerHTML = ''; // Clear previous button, if any
            })
            .catch(error => {
                console.error('Error getting PayPal client token:', error);
                setIsPaypalError(true);
                setPaymentMethod('card');
                if (isPaymentMethodEnabled.card) {
                    handlePaymentChoose("card");
                } else {
                    handlePaymentChoose("bank wire");
                }
                setIsAgreeWithTermsOfService(false);
            })
            .finally(
                () => {
                    setIsPaypalLoading(false);
                });
    }, [props.isCartReadyToProcessPayment]);



    const handleCancelCart = () => {
        props.cancelCart();
        setOrderCurrency(activeCurrency);
    }

    useEffect(() => {
        if (props.paymentMethods.length === 0) {
            return;
        }
        localStorage.setItem('cart', JSON.stringify({
            ...getStorageValue('cart'),
            checkout: { ...getStorageValue('cart').checkout, method: paymentMethod }
        }));

        let _bankWire = false;
        let _card = false;
        let _paypal = false;

        for (let i = 0; i < props.paymentMethods.length; i++) {
            switch (props.paymentMethods[i]?.name) {
                case 'bank wire':
                    if (businessUnit !== 'default_channel' || isTestAccount){
                        logInConsole(`${props.paymentMethods[i].name} method available. ${props.paymentMethods[i].code}`, 'coco');
                        _bankWire = true
                    }
                    else {
                        logInConsole(`${props.paymentMethods[i].name} method not available for default channel customer`, 'coco');
                    }
                    break;
                case 'card':
                    if (!isTestAccount){
                        logInConsole(`${props.paymentMethods[i].name} method available. ${props.paymentMethods[i].code}`, 'coco');
                        _card = true;
                    }
                    else {
                        logInConsole(`${props.paymentMethods[i].name} method not available for Test account`, 'coco');
                    }
                    break;
                case 'paypal':
                    if (businessUnit !== 'default_channel' || isTestAccount){
                        logInConsole(`${props.paymentMethods[i].name} method available. ${props.paymentMethods[i].code}`, 'coco');
                        _paypal = true
                    }
                    else {
                        logInConsole(`${props.paymentMethods[i].name} method not available for default channel customer`, 'coco');
                    }
                    break;
                default:
                    logInConsole("Error : No payment method available");
                    break;
            }
        }

        setIsPaymentMethodEnabled({
            bank: _bankWire,
            card: _card,
            paypal: _paypal,
        });

    }, [props.paymentMethods])

    return (
        <>
            {/*TITLE*/}
            <Typography variant={'h3'}>{t("common.payment")}</Typography>
            {/*PAYMENT METHODS*/}
            {props.isCartReadyToProcessPayment ?
                <>
                    {/*{(props.isGerberUploaded) || (repeatOrderMode.enabled && repeatOrderMode.background) ?*/}
                    {/*    <>*/}

                    {/*    </>*/}
                    {/*    :*/}
                    {/*    <>*/}
                    {/*        <Box component="span" sx={{lineHeight: "25px"}}>*/}
                    {/*            {t("cart.gerberUploadInformation")}*/}
                    {/*            <br/>*/}
                    {/*            {t("cart.gerberMandatoryInformation")}*/}
                    {/*        </Box>*/}
                    {/*    </>*/}
                    {/*}*/}

                    <Typography variant={'h4'}>{t("cart.chooseAPaymentMethod")}</Typography>
                    <Box className="payment-methods-wrapper">
                        {/* CARD */}
                        {
                            isPaymentMethodEnabled.card &&
                            <Box className={paymentMethod === "card" ? "payment-method payment-method-active" : "payment-method"}
                                 onClick={() => handlePaymentChoose("card")}>
                                <Typography component={"span"}>
                                    {t("common.creditCard")}
                                </Typography>
                                <CreditCardTwoToneIcon/>
                            </Box>
                        }

                        {/* BANK WIRE */}
                        {
                            isPaymentMethodEnabled.bank &&
                            <Box className={paymentMethod === "bank wire" ? "payment-method payment-method-active" : "payment-method"}
                                 onClick={() => handlePaymentChoose("bank wire")}>
                                <Typography component={"span"}>
                                    {t("common.bankWire")}
                                </Typography>
                                <AccountBalanceTwoToneIcon/>
                            </Box>
                        }

                        {/*/ PAYPAL /*/}
                        {
                            isPaymentMethodEnabled.paypal &&
                            <Box className={paymentMethod === "paypal" ? "payment-method payment-method-active" : "payment-method"}
                                 onClick={() => handlePaymentChoose("paypal")}>
                                <Typography component={"span"}>
                                    {t("common.paypal")}
                                </Typography>
                                <svg version="1.1"
                                     xmlns="http://www.w3.org/2000/svg"
                                     viewBox="0 0 200 200"
                                     height={25}
                                >
                                    <path fill={"#707071"}
                                          d="M69.7,185.7h30.9c2,0,3.7-1.5,4-3.5l5.5-41.6c0.3-2,2-3.5,4.1-3.5l18.8,0.5c0,0,33.4-0.5,42.6-41.3
                                      c9.9-44.1-31.9-45.8-31.9-45.8l-58-0.9L65.7,181.2C65.5,183.6,67.3,185.7,69.7,185.7z"/>
                                    <path fill={"#040202"}
                                          d="M51.5,20.4L31,162.3c-0.3,2.4,1.5,4.6,4,4.6h33.3l7.8-54.5c0.3-2,1.9-3.4,3.9-3.4l
                                      29.2-0.3c0,0,43.4,1.2,52.5-50.7c8-45.8-39.6-45.6-39.6-45.6H60.9C56.2,12.2,52.2,15.7,51.5,20.4z"/>
                                </svg>
                            </Box>
                        }
                        {
                            !isPaymentMethodEnabled.card && !isVerified &&
                            "No payment method available. please contact quickturn@icape-group.com"
                        }
                    </Box>

                    {/* GPDR CHECKBOX */}
                    <Box className="payment-gpdr">
                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox checked={isAgreeWithTermsOfService}
                                                   onChange={handleIsAgreeWithTermsOfService}/>}
                                label={t("cart.agreeToTermsOfServices")}
                            />
                        </FormGroup>
                        <a href={"https://www.icape-group.com/LegalMentions"} target="_blank" rel="noreferrer">{t("cart.readTheTermsOfServices")}</a>
                    </Box>
                </>
                :
                <Box className={"payment-method-loading"}>
                    <CircularProgress size={20}/> {t("common.loading")}
                </Box>
            }

            {/*CTAs*/}
            <Box className="payment-cta">

                {/*CANCEL BUTTON*/}
                <ButtonIcapeOutlined size="small" onClick={handleCancelCart}
                                     disabled={!props.isCartReadyToProcessPayment}>
                    {t("cta.cancel")}
                </ButtonIcapeOutlined>

                {/*CHECKOUT BUTTON for credit card and bankwire*/}
                {(paymentMethod !== 'paypal' || (paymentMethod === 'paypal' && !isAgreeWithTermsOfService)) &&
                    <ButtonIcapeGreen onClick={props.proceedToCheckoutCallBack} size="small"
                                      disabled={ !isAgreeWithTermsOfService ||
                          paymentMethod === "" ||
                          !props.isCartReadyToProcessPayment ||
                          // (!props.isGerberUploaded && !repeatOrderMode.enabled && !repeatOrderMode.background) ||
                          (!isPaymentMethodEnabled.card && !isVerified)}>
                        {t("cta.checkout")}
                    </ButtonIcapeGreen>
                }

                {/*PAYPAL BUTTON : need to be reachable by DOM to be hydrated so playing with display instead of <> </>*/}
                <Box sx={{display: paymentMethod === 'paypal' && isAgreeWithTermsOfService && !isPaypalLoading ? 'flex' : 'none'}}>
                    {isPaypalLoading && <CircularProgress size={30} />}
                    {/*Client id = each Business unit Paypal account */}
                    {buPaypalData.clientId && <PayPalScriptProvider options={{ "client-id": buPaypalData.clientId , "disable-funding": "credit,card" }}>
                        <div ref={paypalRef}></div>
                    </PayPalScriptProvider>}
                </Box>
            </Box>

            {/*SNACKBAR ERROR PAYPAL BUTTON*/}
            <Snackbar anchorOrigin={{horizontal: 'right', vertical: 'top'}}
                      open={isPaypalError}
                      key='topright'
                      sx={{ width: '100%' }}
                      autoHideDuration={6000}
                      onClose={() => setIsPaypalError(false)}>
                <Alert severity="error" variant="filled"
                       onClose={() => setIsPaypalError(false)}>
                    An error occurred with Paypal information loading. Please try again.
                </Alert>
            </Snackbar>
        </>
    )
}

export default Payment
